import React from 'react'
import { StaticQuery } from 'gatsby'
import moment from 'moment'

import Layout from '../components/layout'
import PageFooter from '../components/PageFooter'
import { mapSingleMarkdownEdge } from '../components/mapMarkdownRemarks'
import { Row, Col } from 'react-flexbox-grid'

const BlogPost = ({ pageContext, blogPost, language }) => {
  return null
}

export default props => (
  <BlogPost language={"fr"} blogPost={{}} data={{}} {...props} />
)
